.button {
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.3rem 1rem;
  font-family: monospace;
  font-size: 16px;
  box-shadow: 1px 2px #000;
  transition: 0.3s ease-out;
  transform: translate(0px, 0px);
}

.button:hover {
  cursor: pointer;
  transform: translate(1px, 2px);
  box-shadow: 0px 0px #000;
}
