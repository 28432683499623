.ascii {
  cursor: default;
}
.move {
  position: absolute;
}
.orbit {
  animation: orbit 4s linear infinite;
}
.orbitReverse {
  animation: orbitReverse 4s linear infinite;
}
.wiggle {
  /* animation: wiggle 1.25s ease-in-out infinite alternate; */
}
@keyframes wiggle {
  0% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(2px);
  }
}
@keyframes orbit {
  0% {
    transform: rotateY(0) translateZ(25px);
  }
  100% {
    transform: rotateY(-360deg) translateZ(25px);
  }
}
@keyframes orbitReverse {
  0% {
    transform: rotateY(0) translateZ(40px);
  }
  100% {
    transform: rotateY(360deg) translateZ(40px);
  }
}
