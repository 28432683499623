.navigation {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: inline-block;
  margin: 4px;
  color: #000;
  text-decoration: none;
  border-bottom: 1px dashed transparent;
  transition: 0.3s ease-out;
}

.link:hover {
  border-bottom: 1px dashed #000;
}
