.options {
  padding: 1rem;
  width: 90%;
  max-width: 750px;
  font-size: 10px;
}

.options input {
  width: 100%;
  height: 60px;
  font-size: 30px;
  text-align: center;
  border: none;
  border-radius: 0;
}

.options select {
  border: 1px solid #000;
  font-size: 16px;
  border-radius: 4px;
  padding: 2px 24px 2px 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 8px;
  background-position: calc(100% - 8px) calc(50% + 2px);
  background-repeat: no-repeat;
  background-color: #fff;
}

.dropdowns {
  margin-left: -4px;
  width: calc(100% + 8px);
  padding: 1rem 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dropdowns > div {
  margin: 4px;
}

.dropdowns label {
  font-size: 10px;
  margin-bottom: 4px;
  display: block;
}

.dropdowns select {
  font-family: monospace, sans-serif;
}

.submit {
  text-align: center;
}
