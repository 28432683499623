.intro-wrap {
  position: fixed;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

.boc-button {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%) !important;
}

.hex-wrapper {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  display: flex;
  align-items: center;
}

.description {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 50px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.reading {
  color: #fff;
  padding: 5%;
  width: 50%;
  min-width: 400px;
}

.reading:first-of-type {
  align-self: flex-start;
}

.reading:last-of-type {
  align-self: flex-end;
}

.hexagram {
  margin: 10px;
  width: 50px;
  display: inline-block;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
  height: 6px;
  background-color: #ddd;
  margin-bottom: 3px;
}

.bar.yin,
.bar.yang {
  background-color: transparent;
}

.bar.yang::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: blue;
}

.bar.yin::before {
  content: "";
  width: 40%;
  height: 100%;
  position: absolute;
  left: 0%;
  background-color: blue;
}

.bar.yin::after {
  content: "";
  width: 40%;
  height: 100%;
  position: absolute;
  left: 60%;
  background-color: blue;
}
